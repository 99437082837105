<template>
	<div id="landEdit">
		<page-land-edit :is-shop="0"></page-land-edit>
	</div>
</template>

<script>
import pageLandEdit from '@/components/layout/land/page-land-edit.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	components:{
		pageLandEdit
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/land/landList'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style>
	
</style>
